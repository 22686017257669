.AppContainer {
  text-align: center;
  background-color: #000;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.BannerSwitcher {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.BannerImage2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.BannerSwitcher.left .BannerImage {
  animation: slideLeft 0.5s forwards;
}

.BannerSwitcher.right .BannerImage {
  animation: slideRight 0.5s forwards;
}

@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.BannerVideo {
  width: 100%;
  height: auto;
  margin-top: -100px;
}

.Banner {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.BannerVideo2 {
  width: 100%;
  height: auto;
}

/* Estilo individual para Banner2 */
.Banner2 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner2 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

/* Estilo individual para Banner3 */
.Banner3 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner3 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

/* Estilo individual para Banner4 */
.Banner4 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner4 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.Banner5 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner5 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.BannerZone {
  position: absolute;
  top: 0;
  width: 50%; /* Cada zona ocupa metade da largura */
  height: 100%;
  cursor: pointer;
  z-index: 5; /* Acima do fundo, mas abaixo de outros elementos */
  overflow: hidden;
  transition: transform 0.5s ease-in-out; /* Transição para movimento */
}

.LeftZone {
  left: 0;
}

.RightZone {
  right: 0;
}

/* Estados animados para zonas */
.BannerZone.left {
  animation: slideZoneLeft 0.5s forwards;
}

.BannerZone.right {
  animation: slideZoneRight 0.5s forwards;
}

@keyframes slideZoneLeft {
  from {
    transform: translateX(-100%); /* Começa fora da tela à esquerda */
  }
  to {
    transform: translateX(0%); /* Termina na posição inicial */
  }
}

@keyframes slideZoneRight {
  from {
    transform: translateX(100%); /* Começa fora da tela à direita */
  }
  to {
    transform: translateX(0%); /* Termina na posição inicial */
  }
}

.BannerSwitcher {
  overflow: visible !important;
}
.ButtonOverlay {
  position: absolute;
  bottom: -6%; /* Alterado para um valor positivo */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 350px; /* Espaçamento entre os botões */
  z-index: 10; /* Certifica-se de que os botões fiquem acima do banner */
  pointer-events: auto; /* Certifica-se de que os botões sejam clicáveis */
}

.ActionButton {
  background-color: transparent;
  border: 4px solid #d9d9d9;
  color: #fff;
  font-family: "Nunito", sans-serif; /* Fonte Nunito */
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.ActionButton:hover {
  background-color: transparent;
  color: #ffffff;
  transform: scale(1.1);
}

.Banner6 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner6 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.Banner7 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;

}

.Banner7 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.Banner8 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
  transform: translateY(-14px); /* Ajuste o valor conforme necessário */
}

.Banner8 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.ButtonOverlay8 {
  position: absolute;
  bottom: 18%; /* Alterado para um valor positivo */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 350px; /* Espaçamento entre os botões */
  z-index: 10; /* Certifica-se de que os botões fiquem acima do banner */
  pointer-events: auto; /* Certifica-se de que os botões sejam clicáveis */
}

/* Adicione a animação pulsar */
@keyframes pulsar {
  0% {
    transform: scale(1); /* Tamanho inicial */
  }
  50% {
    transform: scale(1.1); /* Expande */
  }
  100% {
    transform: scale(1); /* Volta ao tamanho original */
  }
}

/* Atualize a classe do botão para usar a animação */
.ActionButton8 {
  background-color: transparent;
  border: 4px solid #d9d9d9;
  color: #fff;
  font-family: "Nunito", sans-serif; /* Fonte Nunito */
  font-size: 24px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  animation: pulsar 1.5s infinite; /* Aplica a animação */
}

.ActionButton8:hover {
  background-color: transparent;
  color: #ffffff;
  transform: scale(1.1);
}

.Banner9 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner9 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.Banner10 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;

}

.Banner10 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}
.Banner11 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
  transform: translateY(-14px); /* Ajuste o valor conforme necessário */
}

.Banner11 img {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.ButtonOverlay11 {
  position: absolute;
  bottom: 5%; /* Alterado para um valor positivo */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 350px; /* Espaçamento entre os botões */
  z-index: 10; /* Certifica-se de que os botões fiquem acima do banner */
  pointer-events: auto; /* Certifica-se de que os botões sejam clicáveis */
}

/* Adicione a animação pulsar */
@keyframes pulsar {
  0% {
    transform: scale(1); /* Tamanho inicial */
  }
  50% {
    transform: scale(1.1); /* Expande */
  }
  100% {
    transform: scale(1); /* Volta ao tamanho original */
  }
}

/* Atualize a classe do botão para usar a animação */
.ActionButton11 {
  background-color: transparent;
  border: 4px solid #d9d9d9;
  color: #fff;
  font-family: "Nunito", sans-serif; /* Fonte Nunito */
  font-size: 24px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  animation: pulsar 1.5s infinite; /* Aplica a animação */
}

.ActionButton11:hover {
  background-color: transparent;
  color: #ffffff;
  transform: scale(1.1);
}

.Banner12 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  transform: translateY(-14px); /* Ajuste o valor conforme necessário */
}

.Banner12 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}
.Banner13 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
  transform: translateY(-21px); /* Ajuste o valor conforme necessário */
}

.Banner13 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.Banner14 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner14 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.Banner15 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner15 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.Banner16 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner16 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.Banner17 {
  width: 100%;
  padding: 0;  /* Remove qualquer padding */
  margin: 0;   /* Remove qualquer margem */
  overflow: hidden;
}

.Banner17 img {
  width: 100%;  /* Faz a imagem ocupar 100% da largura */
  height: auto; /* Mantém a proporção das imagens */
  display: block; /* Evita espaços extras embaixo da imagem */
}

.Banner18 {
  overflow: hidden;
}

.Banner18 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Banner19 {
  overflow: hidden;
  transform: translateY(-56px); /* Ajuste o valor conforme necessário */
}

.Banner19 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Banner20 {
  overflow: hidden;
  transform: translateY(-63px); /* Ajuste o valor conforme necessário */
}

.Banner20 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Efeito de transição para alternância de banners */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(1.05);
  transition: opacity 500ms, transform 500ms;
}

.BannerSwitcher {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.BannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Button {
  background-color: #222;
  color: #fff;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-top: 40px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.Button:hover {
  background-color: #818083;
  transform: scale(1.1) !important;
}

.IconContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.IconLink {
  color: #fff;
  margin: 0 15px;
  font-size: 36px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.IconLink:hover {
  color: #423f44;
  transform: scale(1.1);
}

.Footer {
  background-color: #000;
  padding: 20px;
  color: #fff;
  font-family: "Nunito", sans-serif; /* Fonte Nunito */
  display: flex;
  justify-content: center; /* Centraliza tudo horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  flex-direction: column; /* Empilha os elementos no centro */
  text-align: center;
}

.FooterContent {
  display: flex;
  align-items: center; /* Centraliza ícones e texto verticalmente */
  gap: 10px; /* Espaçamento entre ícones e texto */
}

.FooterIcons {
  display: flex;
  gap: 15px; /* Espaçamento entre os ícones */
}

.Icon {
  color: #fff; /* Ícones brancos */
  font-size: 24px; /* Tamanho dos ícones */
  transition: transform 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.Icon:hover {
  transform: scale(1.2); /* Leve aumento no hover */
  color: #4caf50; /* Verde para WhatsApp e visual de interação */
}

.videoMobile {
  display: none; /* Esconde o elemento por padrão */
}

.bannerMobile2 {
  display: none; /* Esconde o elemento por padrão */
}

.bannerMobile3 {
  display: none; /* Esconde o elemento por padrão */
}

.bannerMobile4 {
  display: none; /* Esconde o elemento por padrão */
}

.Mobile1 .mobile1-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile2 .mobile2-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile3 .mobile3-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile4 .mobile4-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile5 .mobile5-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile6 .mobile6-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile7 .mobile7-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile8 .mobile8-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile9 .mobile9-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile10 .mobile10-img {
  display: none; /* Esconde o elemento por padrão */
}

.fundo .fundo-img {
  display: none; /* Esconde o elemento por padrão */
}

.Mobile11 .mobile11-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile12 .mobile12-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile13 .mobile13-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile14 .mobile14-img {
  display: none; /* Esconde o elemento por padrão */
}

.ButtonOverlayfundo {
  display: none; /* Esconde os botões no mobile */
  pointer-events: none;
}

.ButtonOverlay14 {
  display: none; /* Esconde os botões no mobile */
  pointer-events: none;
}

.Mobile15 .mobile15-img {
  display: none; /* Esconde o elemento por padrão */
}

.ButtonOverlay15 {
  display: none; /* Esconde os botões no mobile */
  pointer-events: none;
}
.ButtonOverlay20 {
  display: none; /* Esconde os botões no mobile */
  pointer-events: none;
}
.ButtonOverlay25 {
  display: none; /* Esconde os botões no mobile */
  pointer-events: none;
}

.Mobile16 .mobile16-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile17 .mobile17-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile18 .mobile18-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile19 .mobile19-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile20 .mobile20-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile21 .mobile21-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile22 .mobile22-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile23 .mobile23-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile24 .mobile24-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile25 .mobile25-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile26 .mobile26-img {
  display: none; /* Esconde o elemento por padrão */
}
.Mobile27 .mobile27-img {
  display: none; /* Esconde o elemento por padrão */
}
.videoMobile2 {
  display: none; /* Esconde o elemento por padrão */
}

/* Estilos para mobile */
@media only screen and (max-width: 768px) {
 
  .Banner {
    height: auto; /* Ajusta a altura da Banner sem o vídeo */
  }

  .BannerVideo {
    display: none; /* Esconde o BannerVideo no mobile */
  }
  
  .BannerVideo2 {
    display: none; /* Esconde o BannerVideo no mobile */
  }
  .Banner2 .banner2-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner3 .banner3-img {
    display: none; /* Esconde no mobile */
  }
  
  .BannerImage {
    display: none; /* Esconde no mobile */
  }
  
  .ButtonOverlay {
    display: none; /* Esconde os botões no mobile */
    pointer-events: none;
  }
  
  .Banner6 .banner6-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner7 .banner7-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner7 {
    transform: none; /* Remove o deslocamento no mobile */
    display: none; /* Esconde no mobile */
  }
  
  .Banner8 .banner8-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner8 {
    transform: none; /* Remove o deslocamento no mobile */
    display: none; /* Esconde no mobile */
  }
  
  .ButtonOverlay8 {
    display: none; /* Esconde os botões no mobile */
    pointer-events: none;
  }
  
  .Banner9 .banner9-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner10 .banner10-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner11 .banner11-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner12 .banner12-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner12 {
    transform: translateY(-22px); /* Ajuste o valor conforme necessário */
    display: none; /* Esconde no mobile */
  }
  
  .Banner13 .banner13-img {
    display: none; /* Esconde no mobile */
  }
  
  .BannerImage2 {
    transform: translateY(-22px); /* Ajuste o valor conforme necessário */
    display: none; /* Esconde no mobile */
  }
  
  .Banner16 .banner16-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner17 .banner17-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner18 .banner18-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner19 .banner19-img {
    display: none; /* Esconde no mobile */
  }
  
  .Banner20 .banner20-img {
    display: none; /* Esconde o BannerVideo no mobile */
  }
  .videoMobile {
    display: block;
    width: 100%;
    height: auto;
  }

  .bannerMobile2 {
    display: block;
    width: 100%;
    height: auto;
  }

  .bannerMobile3 {
    display: block;
    width: 100%;
    height: auto;
    margin-top: -10px;
  }

  .bannerMobile4 {
    display: block;
    width: 100%;
    height: auto;
  }


.Mobile5 .mobile5-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -5px;
}
.Mobile6 .mobile6-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile7 .mobile7-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile8 .mobile8-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile9 .mobile9-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile10 .mobile10-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}

.fundo .fundo-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}

.fundo {
  position: relative; /* Define a posição do contêiner como referência para os botões */
}

.ButtonOverlayfundo {
  position: absolute;
  bottom: 35%; /* Ajuste a distância em relação à parte inferior do contêiner */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 20px; /* Ajusta o espaçamento entre os botões */
  z-index: 10;
  pointer-events: auto;
  width: 100%; /* Garante que os botões sejam centralizados na largura do contêiner */
}

.ActionButtonfundo {
  background-color: transparent;
  border: 2px solid #d9d9d9;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  animation: pulsar 1.5s infinite;
}

.ActionButtonfundo:hover {
  background-color: transparent;
  color: #ffffff;
  transform: scale(1.1);
}

/* Animação pulsar */
@keyframes pulsar {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


.Mobile11 .mobile11-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile12 .mobile12-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}
.Mobile13 .mobile13-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile14 .mobile14-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}
.Mobile14 {
  position: relative; /* Define a posição do contêiner como referência para os botões */
}

.ButtonOverlay14 {
  position: absolute;
  bottom: 84%; /* Ajuste a distância em relação à parte inferior do contêiner */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 20px; /* Ajusta o espaçamento entre os botões */
  z-index: 10;
  pointer-events: auto;
  width: 100%; /* Garante que os botões sejam centralizados na largura do contêiner */
}

.ActionButton14 {
  background-color: transparent;
  border: 2px solid #d9d9d9;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 8px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.ActionButton14:hover {
  background-color: transparent;
  color: #ffffff;
  transform: scale(1.1);
}



.Mobile15 .mobile15-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}

.Mobile15 {
  position: relative; /* Define a posição do contêiner como referência para os botões */
}



.ButtonOverlay15 {
  position: absolute;
  bottom: 84%; /* Ajuste a distância em relação à parte inferior do contêiner */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 20px; /* Ajusta o espaçamento entre os botões */
  z-index: 10;
  pointer-events: auto;
  width: 100%; /* Garante que os botões sejam centralizados na largura do contêiner */
}

.ActionButton15 {
  background-color: transparent;
  border: 2px solid #d9d9d9;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 8px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.ActionButton15:hover {
  background-color: transparent;
  color: #ffffff;
  transform: scale(1.1);
}



.Mobile16 .mobile16-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}
.Mobile17 .mobile17-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile18 .mobile18-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile19 .mobile19-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile20 .mobile20-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}

.Mobile20 {
  position: relative; /* Define a posição do contêiner como referência para os botões */
}

.ButtonOverlay20 {
  position: absolute;
  bottom: 25%; /* Ajuste a distância em relação à parte inferior do contêiner */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 20px; /* Ajusta o espaçamento entre os botões */
  z-index: 10;
  pointer-events: auto;
  width: 100%; /* Garante que os botões sejam centralizados na largura do contêiner */
}

.ActionButton20 {
  background-color: transparent;
  border: 2px solid #d9d9d9;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  animation: pulsar 1.5s infinite;
}

.ActionButton20:hover {
  background-color: transparent;
  color: #ffffff;
  transform: scale(1.1);
}

/* Animação pulsar */
@keyframes pulsar {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.Mobile21 .mobile21-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile22 .mobile22-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
}
.Mobile23 .mobile23-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -5px;
}
.Mobile24 .mobile24-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}

.Mobile25 .mobile25-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}
.Mobile25 {
  position: relative; /* Define a posição do contêiner como referência para os botões */
}

.ButtonOverlay25 {
  position: absolute;
  bottom: 65%; /* Ajuste a distância em relação à parte inferior do contêiner */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 20px; /* Ajusta o espaçamento entre os botões */
  z-index: 10;
  pointer-events: auto;
  width: 100%; /* Garante que os botões sejam centralizados na largura do contêiner */
}

.ActionButton25 {
  background-color: transparent;
  border: 2px solid #d9d9d9;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  animation: pulsar 1.5s infinite;
}

.ActionButton25:hover {
  background-color: transparent;
  color: #ffffff;
  transform: scale(1.1);
}

/* Animação pulsar */
@keyframes pulsar {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.Mobile26 .mobile26-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}
.Mobile27 .mobile27-img {
  display: block; /* Mostra o elemento em dispositivos móveis */
  width: 100%;
  height: auto;
  margin-top: -1px;
}


.videoMobile2 {
  display: block;
  width: 100%;
  height: auto;
}

.Footer {
  padding: 15px;
}

.FooterIcons {
  gap: 10px; /* Menor espaçamento no mobile */
}

.Icon {
  font-size: 20px; /* Ícones menores no mobile */
}
}
